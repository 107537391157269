import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from '../../model/Auth';


import { AuthService } from '../../service/auth.service';
import { TokenStorageService } from '../../service/token/token.service';
import {NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService} from '@nebular/theme';
import {environment} from '../../../../../environments/environment';
import { Users } from '../../model/Users';

@Component({
  selector: 'ngx-unlocking',
  templateUrl: './unlocking.component.html',
  styleUrls: ['./unlocking.component.scss'],
})
export class UnlockingComponent implements OnInit {
  unlockform: FormGroup;
  email: string;
  users: Users;
  constructor( private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastrService: NbToastrService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
    .subscribe((params) => {
    this.email = params['email'];
    console.log(this.email);
    },
    );
    const formcontrols = {
      answer1: new FormControl('', [Validators.required]),
      answer2: new FormControl('', [Validators.required]),
      answer3: new FormControl('', [Validators.required]),
    };
    this.unlockform = this.fb.group(formcontrols);
    this.authService.getUserByEmail(this.email).subscribe(
      { next : (data: any) => {this.users = data; },
      error : (err) => {console.log(err); }},
    );
  }

  get answer1() {
    return this.unlockform.get('answer1');
  }
  get answer2() {
    return this.unlockform.get('answer2');
  }
  get answer3() {
    return this.unlockform.get('answer3');
  }


  validateInputs(answer1: string, answer2: string, answer3: string): boolean {
    console.log(this.users);
    if (answer1 === this.users.answer1 &&
        answer2 === this.users.answer2 &&
        answer3 === this.users.answer3) {
      return true;
    } else {
      return false;
    }
  }

  onSubmit() {
    if (this.validateInputs(this.answer1.value, this.answer2.value, this.answer3.value)) {
      this.authService.forgetPWD({'email': this.email}).subscribe({
        next: (data: any) => {
          console.log('Success Data:', data);
          this.showToast('Please verify your e-mail to reset your password!', 'success');
          // Set a timeout to delay the navigation so the toast can be seen
          setTimeout(() => {
            console.log('Navigating to login');
            this.router.navigate(['/auth/login']);
          }, 3000); // Delay navigation for 3 seconds
        },
        error: (error) => {
          console.error('Error encountered:', error);
          this.toastrService.danger('An error occurred. Please try again later.', 'Error');
        },
      });
    } else {
      this.toastrService.danger('You have wrong answers, please try again', 'Error');
    }
  }

  showToast(message: string, status: string) {
    // Simply display the toast message
    this.toastrService.success(message, status);
  }

 /* showToast(message, status) {
    this.toastrService.show(status || 'Success', message, { status });
  }*/
  cancelUnlocking() {
    this.router.navigate(['/auth/login']);
   }
}
