<div class="container h-100">
  <div class="row h-100">
    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
      <div class="d-table-cell align-middle">

        <div class="text-center mt-4">
          <tr>
            <img src="assets/images/logo/Manajero-logo.png" alt="" style="width: 40%; margin-left: 180px;"/>
          </tr>
          <tr><h4 style="color: #fcfcfc; margin-left: 180px;">Account Verification</h4></tr>
          <span class="desc">You had 3 or more wrong consecutive login attempts, </span>
          <span class="desc">for security reasons, your account is temporary <strong>LOCKED</strong>. </span>
          <br>
          <span class="desc">Please answer these following questions to proove your identity.</span>
        </div>
        <br>
        <nb-card>
          <nb-card-body>
            <div class="m-sm-4">
              <form [formGroup]="unlockform" (ngSubmit)="onSubmit()">
                <div class="form-group">

                  <label>What is the name of your first pet&#42;</label>
                  <input nbInput type="text" fullWidth
                         name="answer1" id="answer1" formControlName="answer1"
                         required placeholder="Enter your 1st pet's name">

                  <div *ngIf="answer1.touched" class="mt-2">
                    <p class="caption status-danger" *ngIf="answer1.errors?.required">
                      The answer is required!
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label>What is your favorite color&#42;</label>
                  <input nbInput type="text" fullWidth
                         name="answer2" id="answer2" formControlName="answer2"
                         required placeholder="your favorite color">

                  <div *ngIf="answer2.touched" class="mt-2">
                    <p class="caption status-danger" *ngIf="answer2.errors?.required">
                      The answer is required!
                    </p>
                  </div>
                </div>
                <div class="form-group">
                  <label>What is the name of your favorite childhood teacher&#42;</label>
                  <input nbInput type="text" fullWidth
                         name="answer3" id="answer3" formControlName="answer3"
                         required placeholder="favorite childhood teacher name">

                  <div *ngIf="answer3.touched" class="mt-2">
                    <p class="caption status-danger" *ngIf="answer3.errors?.required">
                      The answer is required!
                    </p>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="submit" nbButton status="primary" [disabled]="unlockform.invalid">Confirm</button>
                  <button (click)="cancelUnlocking()" nbButton status="info">Cancel</button>
                </div>
              </form>
            </div>
          </nb-card-body>
        </nb-card>

      </div>
    </div>
  </div>
</div>
