import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {TokenStorageService} from '../auth/service/token/token.service';
@Component({
  selector: 'ngx-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss'],
})
export class ModelsComponent {
  modelsform: FormGroup;
  searchValue: string = '';
  userRole: string; // Ajouter cette ligne
  displayedApps: any[];
  filteredApps: any[];
  apps = [
    { name: 'Administration', icon: 'settings-2-outline' }, // Example icon names
    { name: 'HR', icon: 'people-outline' },
    { name: 'CRM', icon: 'person-done-outline' },
    { name: 'Finance', icon: 'credit-card-outline' },
    { name: 'Accounting', icon: 'book-open-outline' },
    { name: 'Marketing', icon: 'bulb-outline' },
    { name: 'Communication', icon: 'message-square-outline' },
    { name: 'Stock', icon: 'cube-outline' },
    { name: 'Recovery', icon: 'refresh-outline' },
    { name: 'Security', icon: 'shield-outline' },
    { name: 'Monitoring', icon: 'eye-outline' },
    { name: 'Agile project', icon: 'shuffle-2-outline' },
    { name: 'Traditional project', icon: 'archive-outline' },
    { name: 'QHSE', icon: 'checkmark-square-2-outline' },
    { name: 'Compliance', icon: 'checkmark-circle-2-outline' },
    // { name: 'Website', icon: 'assets/images/icons/website.png' },
    // { name: 'Recruitment', icon: 'assets/images/icons/recruitment.png' },
  ];

  constructor(private fb: FormBuilder, private tokenStorageService: TokenStorageService) {
    this.modelsform = this.fb.group({
      searchValue: [''],
    });
    this.modelsform.get('searchValue').valueChanges.subscribe(val => {
      this.filterApps(val);
    });
    const user = this.tokenStorageService.getUser();
    this.userRole = user.role;
    this.displayedApps = this.apps;
  }

  filterApps(val: string) {
    if (!val) {
      this.displayedApps = this.apps;
    } else {
      const searchLower = val.toLowerCase();
      this.displayedApps = this.apps.filter(app =>
        app.name.toLowerCase().startsWith(searchLower),
      );
    }
  }


  canDisplayApp(appName: string): boolean {
    if (appName === 'Administration') {
      return this.userRole === 'ROLE_ADMIN' || this.userRole === 'ROLE_SUPER_ADMIN';
    }
    return true; // Afficher toutes les autres applications
  }

  navigate(appName: string) {
    if (appName === 'Website') {
      window.location.href = 'https://manajero.com';
    }
    if (appName === 'Administration') {
      window.location.href = '/administration';
    }
    if (appName === 'HR') {
      window.location.href = 'https://hcm.manajero.com';
    }
    if (appName === 'CRM') {
      window.location.href = 'https://crm.manajero.com';
    }    if (appName === 'Finance') {
      window.location.href = 'https://finance.manajero.com';
    }    if (appName === 'Accounting') {
      window.location.href = 'https://accounting.manajero.com';
    }    if (appName === 'Communication') {
      window.location.href = 'https://communication.manajero.com';
    }    if (appName === 'Marketing') {
      window.location.href = 'https://marketing.manajero.com';
    }
    if (appName === 'Stock') {
      window.location.href = 'https://stock.manajero.com';
    }
    if (appName === 'Recovery') {
      window.location.href = 'https://recovery.manajero.com';
    }
    if (appName === 'Agile project') {
      window.location.href = 'https://pm.manajero.com';
    }
    /*if (appName === 'Traditional project') {
      window.location.href = 'https://pm.manajero.com';
    }*/
    /*if (appName === 'Security') {
      window.location.href = '';
    }*/
    if (appName === 'Monitoring') {
      window.location.href = 'https://monitoring.manajero.com';
    }
    if (appName === 'QHSE') {
      window.location.href = 'https://quality.manajero.com';
      /*if (appName === 'Compliance') {
        window.location.href = '';
      }*/
    }
    }
}
