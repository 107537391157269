
import {Component, OnInit} from '@angular/core';

import {TokenStorageService} from '../auth/service/token/token.service';

import { AngularFireStorage } from '@angular/fire/compat/storage';

import {ActivatedRoute, Router} from '@angular/router';

import { Location } from '@angular/common';

import { Observable } from 'rxjs';

import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, FormBuilder, FormArray } from '@angular/forms';

import {AuthService} from '../auth/service/auth.service';

import {EmployeeService} from '../humain-capital-management/services/employeeServices/employee.service';

import {Users} from '../auth/model/Users';

import {Employee} from '../humain-capital-management/models/Employee';

import {NbGlobalPhysicalPosition, NbToastrService} from '@nebular/theme';

import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';

import { Country, State, City } from 'country-state-city';
import { DatePipe } from '@angular/common';
import { finalize, timeout } from 'rxjs/operators';

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'splitCamelCase' })

export class SplitCamelCasePipe implements PipeTransform {
  transform(value: string): string {
    return value
      // Insert space before the upper case characters
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // Capitalize the first letter of each word
      .replace(/\b\w/g, char => char.toUpperCase());
  }
}

@Component({

  selector: 'ngx-profile-user',

  templateUrl: './profile-user.component.html',

  styleUrls: ['./profile-user.component.scss'],

  providers: [SplitCamelCasePipe],
})

export class ProfileUserComponent implements OnInit {

  positions = NbGlobalPhysicalPosition;

  companyForm: FormGroup;

  getRole = localStorage.getItem('userrole');




  selectedFile: File = null;

  __fireBase;

  downloadURL: Observable<string>;

  loading = false;

  hasUpdateAccess = false;

  checked = false;

  token: string;

  img: any;

  user: Users = new Users();

  userRole: any;

  employee: Employee = new Employee();

  formArray: FormArray;

  userForm: FormGroup;

  employeeForm: FormGroup;

  showPassword = false;

  shownewPassword = false;

  showcfPassword = false;

  stayConnected: boolean = false;

  imageURL: any;

  file: File;

  // Phone NUmber Properties

  SearchCountryField = SearchCountryField;

  CountryISO = CountryISO;

  PhoneNumberFormat = PhoneNumberFormat;

  maxLengthNumber = 15;

  preferredCountries: CountryISO[] = [CountryISO.Tunisia, CountryISO.Canada, CountryISO.Brazil];

  // Country and Cities Properties

  countryList;

  citiesList;

  companyId: string;

  constructor(

    private tokenStorageService: TokenStorageService,

    private fb: FormBuilder,

    private authService: AuthService,

    private emplService: EmployeeService,

    private router: Router,

    private toastrService: NbToastrService,

    private route: ActivatedRoute,

    private location: Location,

    private storage: AngularFireStorage,
    private datePipe: DatePipe,

  ) {

  }




  ngOnInit(): void {

    this.route.queryParams.subscribe((params) => {

      this.token = params.token;

    });

    this.authService.validateToken(this.token).subscribe({

      next : (res) => {

        this.hasUpdateAccess = res;

      },

      error : (err) => {

        console.log(err);

      },

    });

    this.user = this.tokenStorageService.getUser();

    const passwordValidator: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {

      const currentPassword = control.get('currentPassword');

      const newPassword = control.get('newPassword');




      if (currentPassword && newPassword && currentPassword.value === newPassword.value) {

        return { 'samePassword': true };

      }




      return null;

    };




    this.userForm = this.fb.group({

        'username': new FormControl('', [Validators.required]),

        'email': new FormControl('', [Validators.required, Validators.email]),

        'currentPassword': new FormControl('', [Validators.required]),

        'newPassword': new FormControl(null, [Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/)]),

        'confirmPassword': new FormControl(null),

        'userProfileImage': new FormControl(''),

        'phone': new FormControl('', [Validators.required]),

      },

      {

        validators: [this.ConfirmedValidator('newPassword', 'confirmPassword'), passwordValidator],




      });




    this.employeeForm = new FormGroup({

      'employeeName': new FormControl('', [Validators.required]),

      'employeeProfileImage': new FormControl('', [Validators.required]),

      'employeeCellPhone': new FormControl('', [Validators.required]),

      'employeeCountry': new FormControl('', [Validators.required]),

      'employeeCity': new FormControl('', [Validators.required]),

    });




    this.getUserData();




    this.countryList = Country.getAllCountries();


    this.companyForm = this.fb.group({

      id: [''],

      companyName: ['', Validators.required],

      address: [''],

      companyCity: ['', Validators.required],

      companyCountry: ['', Validators.required],

      postalCode: [''],

      slogan: [''],

      description: [''],

      activity: [''],

      identNumber: [''],

      services: [''],

      serviceDesc: [''],

      companyPhone: ['', Validators.required],

      companyEmail: ['', Validators.email],

      categoryProduct: [''],

      pricingGrid: [''],

    });

    this.populateForm();

  }




  populateForm(): void {

    if (this.user && this.user.company) {

      this.companyForm.patchValue(this.user.company);

    }

  }

  get username() {

    return this.userForm.get('username');

  }

  get phone() {

    return this.userForm.get('phone');

  }

  get email() {

    return this.userForm.get('email');

  }

  get currentPassword() {

    return this.userForm.get('currentPassword');

  }

  get newPassword() {

    return this.userForm.get('newPassword');

  }

  get confirmPassword() {

    return this.userForm.get('confirmPassword');

  }

  get employeeName() {

    return this.employeeForm.get('employeeName');

  }

  get employeeProfileImage() {

    return this.employeeForm.get('employeeProfileImage');

  }

  get employeeCellPhone() {

    return this.employeeForm.get('employeeCellPhone');

  }

  get employeeCountry() {

    return this.employeeForm.get('employeeCountry');

  }

  get employeeCity() {

    return this.employeeForm.get('employeeCity');

  }

  getUserData() {

    this.authService.getUser(this.user.id).subscribe({

      next : (data: any) => {

        this.user = data;

        if (this.user.role !== 'ROLE_SUPER_ADMIN') {

          if (this.user.userProfileImage && this.user.userProfileImage !== '') {

            this.img = this.user.userProfileImage.toString();

          } else {

            this.img = 'assets/images/defaultuser.png';

          }

        } else {

          this.img = 'assets/images/logo/Manajero-logo.png';

        }

        this.user.roles.map((r: any) => this.userRole = r.name);

        this.userForm.patchValue({

          'username': this.user.username,

          'email': this.user.email,

          'phone': this.user.phone,

        });

      },

      complete : () => {

        if (this.userRole !== 'ROLE_SUPER_ADMIN') {

          this.getEmployeeData();

          console.log(this.userRole);

        }

      },

    });

    console.log(this.user);

  }






  getEmployeeData() {

    this.emplService.getemployeebyuser(this.user.id).subscribe((employee: any) => {

      this.employee = employee;

      this.employeeForm.patchValue({

        'employeeName': this.employee.employeeName,

        'employeeProfileImage': this.employee.employeeProfileImage,

        'employeeCountry': this.employee.employeeCountry,

        'employeeCellPhone': this.employee.employeeCellPhone,

        'employeeCity': this.employee.employeeCity,

      });

      this.getImageEmployee(this.user.id);

    });

  }

  getImageEmployee(id: string) {

    this.emplService.getImageEmployee(id).subscribe(response => {

      const fileBlob = response.body;

      const reader = new FileReader();

      reader.onload = (e: any) => {

        this.employeeForm.patchValue({

          'employeeProfileImage': e.target.result,

        });

      };

      reader.readAsDataURL(fileBlob);

    });




  }

  onSubmitFileSelected(file: File) {




    if (file) {

      const reader = new FileReader();

      reader.onload = (e: any) => {

        const imageBytes: ArrayBuffer = e.target.result;

        const blob = new Blob([imageBytes], { type: this.file.type });

        const convertedFile = new File([blob], this.file.name, { type: this.file.type });

        this.updateImageEmployee(convertedFile);

      };

      reader.readAsArrayBuffer(this.file);

    }

  }






  onFileSelected(event: any) {

    this.file = event.target.files[0];




    if (this.file) {

      const reader = new FileReader();

      const readerShow = new FileReader();




      readerShow.onload = (e: any) => {

        this.employeeForm.patchValue({

          'employeeProfileImage': e.target.result,

        });

      };

      readerShow.readAsDataURL(this.file);

    }

    console.log(this.employeeForm.invalid);




  }

  toggleLoadingAnimation(event) {

    this.loading = true;

    this.onFileSelectedUser(event);

    setTimeout(() => this.loading = false, 3000);

  }

  onFileSelectedUser(event) { const n = Date.now();

    const file = event.target.files[0];

    const filePath = `users/${n}`;

    const fileRef = this.storage.ref(filePath); const task = this.storage.upload(`users/${n}`, file);

    task

      .snapshotChanges()

      .pipe(

        finalize(() => {

          this.downloadURL = fileRef.getDownloadURL();

          this.downloadURL.subscribe(url => {if (url) {

            this.user.userProfileImage = url;

          }

          });

        }),

      )

      .subscribe(url => {

        if (url) {

          console.log('url', url);

        }

      });

  }

  updateImageEmployee(file: File) {

    const id = this.employee.id; // Remplacez par l'ID approprié de l'employé

    console.log(file);

    if (file) {

      this.emplService.updateImageEmployee(id, file).subscribe(

        (response) => {

          console.log('Image mise à jour avec succès :', response);

          // Effectuez d'autres opérations après la mise à jour de l'image

        },

        (error) => {

          console.error('Erreur lors de la mise à jour de l\'image :', error);

          // Gérez l'erreur de mise à jour de l'image

        },

      );

    }

  }

  SendMail() {

    this.authService.sendmail(this.user.email).subscribe((res: any) => {

        console.log(res);

        this.showToast('Access mail sent to ' + this.user.email, 'Success', this.positions.TOP_RIGHT);

        this.router.navigate(['/confirmation-mail']);




      },

      (error) => {

        console.log(error);

        this.showToast('Something went wrong. Try again later', 'Error', this.positions.TOP_RIGHT);




      },

      () => {

      });

  }

  toggle(checked: boolean) {

    this.checked = checked;

  }

  updateUser() {
    console.log(this.userForm.value);

    const encodedPassword = encodeURIComponent(this.currentPassword.value);

    this.authService.checkPassword(this.user.password, encodedPassword).subscribe(

      (data: boolean) => {

        if (data) {

          const phoneObject = this.phone.value;

          this.user.username = this.username.value;

          this.user.email = this.email.value;

          this.user.phone = phoneObject.number;




          if (this.newPassword.value != null) {

            this.user.password = this.newPassword.value;

          } else {

            this.user.password = this.currentPassword.value;

          }




          this.authService.updateUser(this.user).subscribe(

            (res: any) => {

              console.log(res);

            },

            (error) => {

              console.log(error);

            });




          if (this.employee.employeeEmail !== this.user.email) {

            this.employee.employeeEmail = this.user.email; // hedhi matjich se3a tforci fih ywali nafs user mail w employee mail




            this.emplService.editProfile(this.user.id, this.employee).subscribe(

              (res: any) => {

                console.log(res);

              },

              (error) => {

                console.log(error);

              },

            );

          }

          this.showToast('User Data updated', 'Success', this.positions.TOP_RIGHT);

          setTimeout(() => {

            this.fullPageReload();

          }, 2000);




        } else {

          this.showToast('Wrong Password', 'Error', this.positions.TOP_RIGHT);

        }




      },

      (error) => {

        console.log(error);

        this.showToast('Something went wrong. Try again later', 'Error', this.positions.TOP_RIGHT);

      });

    if (!this.checked) {

      this.tokenStorageService.signOut();

      this.fullPageReload();

    }

  }

  fullPageReload() {

    // Use the window.location.reload() method for a full page reload

    window.location.reload();

  }

  updateEmployee() {

    this.employee.employeeName = this.employeeName.value;

    this.employee.employeeCellPhone = this.employeeCellPhone.value.internationalNumber;

    this.employee.employeeCountry = this.employeeCountry.value.name;

    this.employee.employeeCity = this.employeeCity.value;

    // this.employee.employeeProfileImage = this.employeeProfileImage.value;

    this.onSubmitFileSelected(this.file);




    this.emplService.editProfile(this.user.id, this.employee).subscribe(

      (res: any) => {

        this.showToast('Employee Data updated', 'Success', this.positions.TOP_RIGHT);

        window.location.reload();

      },

      (error) => {

        console.log(error);

        this.showToast('Something went wrong. Try again later', 'Error', this.positions.TOP_RIGHT);

      },

    );

  }

  ConfirmedValidator(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {

      const control = formGroup.controls[controlName];

      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {

        return;

      }

      if (control.value !== matchingControl.value) {

        matchingControl.setErrors({ confirmedValidator: true });

      } else {

        matchingControl.setErrors(null);

      }

    };

  }




  updateCompany(): void {
    if (this.companyForm.invalid) {
      this.toastrService.danger('Please fill in all required fields', 'Form Error', { position: this.positions.TOP_RIGHT });
      return;
    }
    // Prepare the data for sending
    const formData = this.companyForm.value;

    const companyData = {
      ...formData,
      companyCountry: formData.companyCountry.name, // Assuming the backend expects just the country name as a string
      companyPhone: formData.companyPhone.internationalNumber, // Assuming the backend expects just the phone number as a string
    };

    console.log('Sending:', companyData);

    this.authService.updateCompany(companyData).pipe(
      finalize(() => this.loading = false),
    ).subscribe({
      next: (res) => {
        this.toastrService.success('Company updated successfully', 'Success', { position: this.positions.TOP_RIGHT });
        // Delay the reload to allow the toast message to be seen by users
        setTimeout(() => {
          this.tokenStorageService.signOut();
          this.fullPageReload();
        }, 2000); // Adjust delay as needed for user experience
      },

      error: (err) => {
        console.error('Failed to update company:', err);
        this.toastrService.danger('Error updating company. Check the console for more details.', 'Error', { position: this.positions.TOP_RIGHT });
      },
    });
  }



  /* updateCompany(){

    console.log("not yet")

  } */

  toggleShowPassword() {

    this.showPassword = !this.showPassword;

  }

  toggleShowNewPassword() {

    this.shownewPassword = !this.shownewPassword;

  }

  toggleShowCfPassword() {

    this.showcfPassword = !this.showcfPassword;

  }

  showToast(message, title, position) {

    this.toastrService.show(message, title, { position });

  }

  getEmployeeCountryStates() {

    const country = this.employeeForm.get('employeeCountry').value;

    if (country) {

      this.citiesList = State.getStatesOfCountry(country.isoCode);

    }

  }




  // This function retrieves cities for the company's selected country and states

  getCompanyCountryStatesAndCities() {

    const country = this.companyForm.get('companyCountry').value;

    if (country) {

      this.citiesList = State.getStatesOfCountry(country.isoCode);




    }

  }

}




